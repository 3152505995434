import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import Nanobar from 'nanobar';
import AllMarkers from './AllMarkers';
import cf from '../../assets/cf_temp_logo.png';

require('dotenv').config();

const Home = () => {
  document.getElementsByTagName('body')[0].classList.add('hideYX');
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
  function runonce() {
    const options = {
      classname: 'my-class',
      id: 'my-id',
      target: document.getElementById('bigpoppa'),
    };

    const nanobar = new Nanobar(options);

    nanobar.go(30);

    const moveLocater = () => {
      document.getElementById('cf-logo').classList.add('opacityzero');
      setTimeout(() => {
        document.getElementById('table').classList.add('move_right');

        setTimeout(() => {
          document.getElementById('shrink').classList.add('animate_shrink');

          setTimeout(() => {
            document.getElementById('shrink').classList.add('opacityzero');
            document.getElementById('inner-container').classList.add('opacityin');
            document.getElementsByTagName('body')[0].classList.remove('hideYX');
            document.getElementById('filter-text').classList.add('opacityin');
            document.getElementById('about-link').classList.add('opacityin');
            document.getElementById('mainlogo').classList.add('opacityin');
            document.getElementById('socials').classList.add('opacityin');

            setTimeout(() => {
              document.getElementById('shrink').classList.add('hide');
            }, 700);
          }, 800);
        }, 700);
      }, 300);
    };

    setTimeout(() => {
      nanobar.go(60);
      setTimeout(() => {
        nanobar.go(100);
        moveLocater();
      }, 1000);
    }, 1000);
  }

  runonce();

  return (
    <div id="table">
      <div className="slide">
        <div id="home_container" className="container flex">
          <Link id="about" style={{ display: 'none' }} to="/about" />

          <div className="split flex">
            <div className="vcenter home-container">
              <img id="cf-logo" style={{ width: '37vw' }} src={cf} alt="Certified Fresh Logo" />
            </div>
          </div>
        </div>
      </div>

      <div className="slide">
        <div

          className="about flex"
          style={{ backgroundColor: 'white', overflow: 'hidden' }}
        >
          <AllMarkers />
          <div
            id="shrink"
            style={{
              height: '100vh',
              width: '100vw',
              backgroundColor: '#e6e844',
              position: 'absolute',
            }}
          />
        </div>
      </div>
      <div className="slide" />
    </div>
  );
};

export default Home;
