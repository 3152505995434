import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Home from './pages/Home/Home';
import './App.css';
import logo from './assets/logo.svg';

library.add(fab);
const PageFade = (props) => (
  <CSSTransition
    {...props}
    classNames="slideIn"
    timeout={1000}
    mountOnEnter
    unmountOnExit
  />
);

const Layout = ({ children }) => (
  <div>
    <div id="bigpoppa" className="fadein">
      <div className="overlay">
        <div className="revealer" />
      </div>
      <section id="parent">
        <img id="mainlogo" className="opacityzero" width="100" src={logo} alt="Garage Project Logo" />
        <nav>
          <div className="right">
            <ul>
              <li>
                <div id="socials" className="socials opacityzero">
                  <a href="https://www.instagram.com/garageproject/"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                  <a href="https://www.facebook.com/garageproject/"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        {children}
      </section>
    </div>
  </div>
);

const AppContent = ({ location }) => {
  const { pathname: locationKey } = location;
  return (
    <Layout>
      <TransitionGroup>
        <PageFade key={locationKey}>
          <section className="fix-container">
            <Switch location={location}>
              <Route exact path="/" component={Home} />
            </Switch>
          </section>
        </PageFade>
      </TransitionGroup>
    </Layout>
  );
};

const App = () => <Route path="/" component={AppContent} />;

export default App;
