import React, { Component } from 'react';
import './Home.css';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import reqwest from 'reqwest';

const demoFancyMapStyles = require('./mapstyles.json');
require('dotenv').config();

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      zoom={props.zoom}
      center={{ lat: props.center.lat, lng: props.center.lng }}
      defaultOptions={{ styles: demoFancyMapStyles }}
      mapTypeControl={false}
    >
      {props.markers.map((marker) => (
        <Marker
          {...marker}
          key={marker.id}
          options={{ icon: { url: `${process.env.PUBLIC_URL}/marker.png` } }}
          onClick={() => props.onMarkerClick(marker.id)}
        >
          {marker.showInfo && (
          <InfoWindow onCloseClick={() => props.onMarkerClose(marker)}>
            <div style={{ maxWidth: '270px' }}>
              <h3 style={{ fontSize: '16px', paddingTop: '0px' }}>{marker.name}</h3>
              <h1 className="locate_h1 region pointer">{marker.address}</h1>
            </div>
          </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  )),
);

class AllMarkers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markers: [],
      zoom: 6,
      center: {
        lat: -41.2222907,
        lng: 173.4093149,
      },
      regions: [
        {
          name: 'Upper North',
          key: 'upper_north',
          position: {
            lat: -35.9986351,
            lng: 174.0504645,
          },
          zoom: 7.6,
        },
        {
          name: 'Central',
          key: 'central_north',
          position: {
            lat: -38.2296543,
            lng: 176.4403251,
          },
          zoom: 7.5,
        },
        {
          name: 'Lower North',
          key: 'lower_north',
          position: {
            lat: -40.6178607,
            lng: 174.9160864,
          },
          zoom: 7.9,
        },
        {
          name: 'Upper South',
          key: 'upper_south',
          position: {
            lat: -42.4049326,
            lng: 172.4012928,
          },
          zoom: 7.73,
        },
        {
          name: 'Lower South',
          key: 'lower_south',
          position: {
            lat: -45.4201568,
            lng: 169.1168152,
          },
          zoom: 7.7,
        },
      ],
    };
  }

  handleMarkerClick = this.handleMarkerClick.bind(this);

  handleMarkerClose = this.handleMarkerClose.bind(this);

  componentWillMount() {
    this.fetch();
  }

  handleMarkerClick(targetMarker) {
    const { markers } = this.state;
    this.setState({
      markers: markers.map((marker) => {
        if (marker.id === targetMarker) {
          this.setState({
            center: {
              lat: marker.position.lat,
              lng: marker.position.lng,
            },
          });
          return {
            ...marker,
            showInfo: true,
          };
        }
        return {
          ...marker,
          showInfo: false,
        };
      }),
    });
  }

  handleMarkerClose(targetMarker) {
    const { markers } = this.state;
    this.setState({
      markers: markers.map((marker) => {
        if (marker === targetMarker) {
          return {
            ...marker,
            showInfo: false,
          };
        }
        return marker;
      }),
    });
  }

  fetch = () => {
    // const { markers } = this.state;
    const baseURL = process.env.REACT_APP_NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.gpmatrix.co.nz';
    reqwest({
      url: `${baseURL}/certified-fresh/retailers`,
      method: 'get',
      type: 'json',
      // headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Content-Type' },
    }).then((retailers) => {
      for (let i = 0; i < retailers.length; i += 1) {
        const {
          id,
          retailer_name: name,
          region,
          address,
          latitude: lat,
          longitude: lng,
        } = retailers[i];
        this.setState(({ markers }) => ({
          markers: [
            ...markers,
            {
              id,
              position: {
                lat,
                lng,
              },
              name,
              region,
              address,
            },
          ],
        }));
      }
    },
    (error) => {
      console.log(error);
    });
  };

  onFilterByRegion = (e, selectedRegion) => {
    let filterlat;
    let filterlng;
    let filterzoom;
    document.getElementById('store-container').classList.remove('hideYX');
    document.getElementById('store-container').classList.add('opacityin');

    for (let i = 0; i < document.getElementsByClassName('region-container').length; i += 1) {
      document.getElementsByClassName('region-container')[i].classList.remove('notorious');
    }

    e.target.parentNode.classList.add('notorious');
    for (let i = 0; i < document.getElementsByClassName('region-container').length; i += 1) {
      if (document.getElementsByClassName('region-container')[i].classList !== 'region-container opacitylil') {
        document.getElementsByClassName('region-container')[i].classList.add('opacitylil');
      }
    }

    const { regions } = this.state;
    for (let i = 0; i < regions.length; i += 1) {
      if (regions[i].key === selectedRegion) {
        filterlat = regions[i].position.lat;
        filterlng = regions[i].position.lng;
        filterzoom = regions[i].zoom;
      }
    }

    this.setState({
      zoom: filterzoom,
      center: {
        lat: filterlat,
        lng: filterlng,
      },
      selectedRegion,
    });
  };

  goToStore = (e) => {
    const lat = parseFloat(e.target.parentNode.dataset.lat);
    const lng = parseFloat(e.target.parentNode.dataset.lng);
    const id = parseFloat(e.target.parentNode.dataset.id);
    this.setState({
      zoom: 15,
      center: {
        lat,
        lng,
      },
    });
    this.handleMarkerClick(id);
  };

  handleKeyPress = () => {
    // TODO: Handle key presses instead of clicking for accessibility
  }

  render() {
    const {
      markers, selectedRegion, zoom, center,
    } = this.state;

    return (
      <div id="slide-container" style={{ display: 'flex' }}>
        <div id="footer"><h3 id="about-link" className="opacityzero"><a href="https://www.garageproject.co.nz">About</a></h3></div>
        <div id="filters">
          <div id="filter-containers">
            <div id="filter-text" className="opacityzero">
              <h1 className="locate_h1">Certifed Fresh Retailers</h1>
              <h2 className="">Find a Retailer</h2>
            </div>
            <div id="inner-container">
              <div
                className="region-container"
                id="upper-north"
                onClick={(e) => this.onFilterByRegion(e, 'upper_north')}
                role="button"
                tabIndex={0}
                onKeyDown={this.handleKeyPress}
              >
                <h3 className="pointer">Upper North</h3>
                <h1 className="locate_h1 region pointer">View Region</h1>
              </div>

              <br />

              <div
                className="region-container"
                onClick={(e) => this.onFilterByRegion(e, 'central_north')}
                role="button"
                tabIndex={-1}
                onKeyDown={this.handleKeyPress}
              >
                <h3 className="pointer">Central</h3>
                <h1 className="locate_h1 region pointer">View Region</h1>
              </div>

              <br />

              <div
                className="region-container"
                onClick={(e) => this.onFilterByRegion(e, 'lower_north')}
                role="button"
                tabIndex={-2}
                onKeyDown={this.handleKeyPress}
              >
                <h3 className="pointer">Lower North</h3>
                <h1 className="locate_h1 region pointer">View Region</h1>
              </div>
              <br />

              <div
                className="region-container"
                onClick={(e) => this.onFilterByRegion(e, 'upper_south')}
                role="button"
                tabIndex={-3}
                onKeyDown={this.handleKeyPress}
              >
                <h3 className="pointer">Upper South</h3>
                <h1 className="locate_h1 region pointer">View Region</h1>
              </div>

              <br />

              <div
                className="region-container"
                onClick={(e) => this.onFilterByRegion(e, 'lower_south')}
                role="button"
                tabIndex={-4}
                onKeyDown={this.handleKeyPress}
              >
                <h3 className="pointer">Deep South</h3>
                <h1 className="locate_h1 region pointer">View Region</h1>
              </div>

            </div>
            <div id="store-container" className="opacityzero hideYX nocursor">
              {markers.map(({
                id, name, address, region: retailerRegion, position,
              }) => (retailerRegion === selectedRegion ? (
                <div
                  className="store"
                  key={id}
                  data-id={id}
                  data-lng={position.lng}
                  data-lat={position.lat}
                  onClick={(e) => this.goToStore(e, 'LN')}
                  role="button"
                  tabIndex={-5}
                  onKeyDown={this.handleKeyPress}
                >
                  <h3 className="pointer">{name}</h3>
                  <h1 className="locate_h1 region pointer">{address}</h1>
                </div>
              ) : null))}
            </div>
          </div>
        </div>
        <MyMapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDcOhRBmQ2ZHTQO_oaoOK94zmhAKb4Teng&libraries=geometry,drawing,places"
          loadingElement={(
            <div
              style={{ height: '100vh', width: '50vw' }}
            />
          )}
          containerElement={<div id="map-container-elelment" />}
          mapElement={<div id="map-element" />}
          markers={markers}
          zoom={zoom}
          onMarkerClick={this.handleMarkerClick}
          onMarkerClose={this.handleMarkerClose}
          center={center}
          isMarkerShown
        />
      </div>
    );
  }
}

export default AllMarkers;
